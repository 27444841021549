import * as React from 'react'

interface IPropTypes {
  className?: string
  value: any
  onChange: React.ChangeEventHandler
}

const SearchInput = ({ className, value, onChange }: IPropTypes) => {
  return (
    <input
      className={`${className} w-full bg-secondary focus:border-none focus:outline-none focus:ring focus:ring-[#12cf7e] px-4 py-2 mb-6 rounded-md text-gray-200`}
      placeholder="Search..."
      type="text"
      aria-label="Search"
      value={value}
      onChange={onChange}
    />
  )
}

export default SearchInput
