import * as React from 'react'

import { graphql, Link } from 'gatsby'

import Layout from '../components/core/Layout'
import BlogCard from '../components/Cards/Standard/BlogCard'
import SearchInput from '../components/Input/SearchInput'

interface IPropTypes {
  data: any
}

// Needs pagination and search
const Blog = ({ data }: IPropTypes) => {
  const [state, setState] = React.useState({
    filteredPosts: [],
    query: '',
  })

  const allPosts = data.allMdx.nodes
  const posts = state.query ? state.filteredPosts : allPosts

  const handleInputChange = (event: any) => {
    const query = event.target.value
    const filteredPosts = allPosts.filter((post: any) => {
      const { title } = post.frontmatter
      return title.toLowerCase().includes(query.toLowerCase())
    })
    setState({
      query,
      filteredPosts,
    })
  }

  return (
    <Layout
      title="Blog"
      description="The perfect blog for content on front-end, engineering, information
      visualization and London,"
    >
      <div className="max-w-3xl mx-auto">
        <div className="flex flex-col mx-6 mt-12">
          <h1 className="font-bold text-primary text-5xl mb-4">Blog</h1>
          <p className="mb-4">
            If you're interested in front-end, engineering, information
            visualization and London, then this might be the place for you! Try
            out the search if you're looking for something specific. I recently
            embarked on a mission to rebuilt this site - more content coming
            soon!
          </p>
          <div className="flex flex-col gap-x-6">
            {/* add a search icon on the left and the number of blog posts on the right hand side see KCD */}
            <SearchInput value={state.query} onChange={handleInputChange} />
            {posts.map((post: any) => {
              return (
                <Link to={`/blog/${post.slug}`} key={post.slug}>
                  <BlogCard
                    title={post.frontmatter.title}
                    description={post.frontmatter.description}
                    tags={post.frontmatter.tags}
                  />
                </Link>
              )
            })}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { category: { eq: "blog" } } }
    ) {
      nodes {
        slug
        frontmatter {
          title
          description
          date
          tags
        }
      }
    }
  }
`

export default Blog
