import * as React from 'react'

import { Link } from 'gatsby'

interface IPropTypes {
  title: string
  description: string
  tags?: Array<string>
}

const BlogCard = ({ title, description, tags }: IPropTypes) => {
  return (
    <div className="col-span-12 transition ease-in-out hover:scale-[1.01]">
      <div className="mt-4">
        <h3 className="font-bold text-xl text-primary">{title}</h3>
        <div className="flex flex-row gap-x-3">
          {tags &&
            tags.map((tag: string) => {
              return (
                <Link
                  to={`/tags/${tag}`}
                  className="text-sm text-primary font-semibold hover:text-[#12cf7e] py-1 rounded-md"
                >
                  {tag}
                </Link>
              )
            })}
        </div>
        <p className="text-primary opacity-60">{description}</p>
      </div>
    </div>
  )
}

export default BlogCard
